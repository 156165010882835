
import NumberStepper from '@/components/NumberStepper/NumberStepper'

export default {
  name: 'CartQuantitySelector',
  components: {
    NumberStepper,
  },
  props: {
    stock: { type: Object, default: null },
    startValue: { type: Number, default: 1 },
    productStepper: { type: Boolean, default: true },
  },
  data() {
    return {
      quantity: this.startValue,
      outOfStock: false,
    }
  },
  watch: {
    startValue: {
      handler(newValue) {
        this.quantity = newValue
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.stock.buyQuantity === 0) {
      this.quantity = 0
    }
  },
  methods: {
    setQuantity(value) {
      this.$analytics.sendTagEvent(this.$events.TAG_PLAN.CART.UPDATE_PRODUCT_QUANTITY, {
        new_unit_amount: value,
      })
      this.quantity = Math.max(1, value)
      this.quantity = Math.min(this.stock.buyQuantity, this.quantity)
      this.onQuantityChange()
    },
    onQuantityChange() {
      this.$emit('onCartQuantityChange', this.quantity)
    },
  },
}
