
import { mapGetters } from 'vuex'
import { debounce } from '@/utils'

import Mobile from '@/mixins/mobile-mixin'

import MainButton from '@/components/Buttons/MainButton/MainButton'
import QuantitySelector from '@/components/Cart/CartQuantitySelector/CartQuantitySelector'

export default {
  name: 'AddToCart',
  components: {
    MainButton,
    QuantitySelector,
  },
  mixins: [Mobile],
  props: {
    stock: { type: Object, default: null },
    fastAddToCart: { type: Boolean, default: false },
    elementTest: { type: String, default: '' },
  },
  data() {
    return {
      quantity: 1,
      showStickyBtn: false,
    }
  },
  computed: {
    ...mapGetters('club', ['isClub']),
    isMobileDevice() {
      return !this.$ua.isFromPc()
    },
    addedFast() {
      return this.$store.getters['cart/isInCart'](this.stock.uid)
    },
  },
  mounted() {
    if (!this.fastAddToCart) {
      window.addEventListener('scroll', debounce(this.checkShowStickBtn, 300), { passive: true })
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkShowStickBtn)
  },
  methods: {
    checkShowStickBtn() {
      let elementTarget = document.getElementById('companyDeliveryDetails')
      if (elementTarget) {
        this.showStickyBtn =
          window.scrollY - elementTarget.offsetTop - document.querySelector('.menu-top').offsetHeight >
          elementTarget.offsetTop + elementTarget.offsetHeight
      }
    },
    onQuantityChange(value) {
      this.quantity = value
    },
    addToCart() {
      this.$root.$emit('addToCartAction', {
        accepted: false,
        stock: this.stock,
        quantity: this.quantity,
        fastAddToCart: this.fastAddToCart,
      })
      this.$emit('addToCart')
    },
  },
}
